import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Callout, FormGroup, InputGroup } from "@blueprintjs/core";
import { UserContext } from "../context/UserContext";

const Login = () => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);

    const formSubmitHandler = e => {
        e.preventDefault();
        setIsSubmitting(true);
        setError("");

        const genericErrorMessage = "Something went wrong! Please try again later.";

        const BASE_URL = process.env.REACT_APP_API_ENDPOINT
        fetch(BASE_URL + "users/login", {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: email, password }),
        })
            .then(async response => {
                setIsSubmitting(false)
                if (!response.ok) {
                    if (response.status === 400) {
                        setError("Please fill all the fields correctly!");
                    } else if (response.status === 401) {
                        setError("Invalid email/password combination");
                    } else {
                        setError(genericErrorMessage);
                    }
                } else {
                    const data = await response.json()
                    setUserContext(oldValues => {
                        return { ...oldValues, token: data.token }
                    })
                    navigate('/my-bets');
                }
            })
            .catch(error => {
                setIsSubmitting(false);
                setError(genericErrorMessage);
            })
    }

    const handleGuestClick = () => {
        navigate('/odds-aggregator');
    }

    return (
        <div>
            {error && <Callout intent="danger">{error}</Callout>}
            <form onSubmit={formSubmitHandler} className='auth-form'>
                <FormGroup label="Email" labelFor="email">
                    <InputGroup 
                        id="email" 
                        placeholder="Email" 
                        type="email" 
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                    
                    />
                </FormGroup>
                <FormGroup label="Password" labelFor="password">
                    <InputGroup 
                        id="password" 
                        placeholder="Password" 
                        type="password" 
                        value={password} 
                        onChange={e => setPassword(e.target.value)} 
                    
                    />
                </FormGroup>
                <Button
                    intent="primary"
                    disabled={isSubmitting}
                    text={`${isSubmitting ? "Signing In" : "Sign In"}`}
                    fill
                    type="submit"
                />
                
                <p>or</p>

                <Button
                    intent="secondaty"
                    text="Preview as Guest"
                    fill
                    type="button"
                    onClick={handleGuestClick}
                />
            </form>
        </div>
    )
}

export default Login
