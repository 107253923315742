import { React, useContext, useEffect, useState } from 'react'
import EventCard from './EventCard'
import { EventsContext } from '../context/EventsContext';
import PickEmEventCard from './PickEmEventCard';
import { v4 as uuidv4 } from 'uuid';

function PickEmEventContainer({ sport, selectedBets, setSelectedBets }) {
  const games = useContext(EventsContext);
  
  return (
    <div className="events-container">
            <div className="events-header">
            <div className="events-header-title">Games</div>
            </div>
            {games.map((game) => (
             <PickEmEventCard key={uuidv4()} sport={sport} customEventId={game.customEventId} commenceTime = {game.commence_time} awayTeam={game.away_team} homeTeam={game.home_team} bookmaker={game.bookmakers} selectedBets={selectedBets} setSelectedBets={setSelectedBets} />
            ))}
    </div>
  )
}

export default PickEmEventContainer
