import React from 'react'

// Component that lists options for user to select, adds 'selected' class to option that is clicked. 

const SelectorBar = ({ options, selectedOption, onOptionChange }) => {
  const handleOptionClick = (option) => {
    onOptionChange(option); // Define function before rendering a SelectorBar component, along with the useState and array of desired options
  };
  
  return (
    <div className="selector-bar">
        {options.map((option) => (
          <div
            key={option}
            className={`option ${option === selectedOption ? 'selected' : ''}`}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </div>
        ))}
    </div>
  );
}

export default SelectorBar
