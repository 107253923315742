import React from 'react'

const SingleBetCard = ({ betDetails, eventDetails }) => {
    const commenceTimeDate = new Date(eventDetails.commence_time);

    // Get short form of day of the week
    const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(commenceTimeDate);

    // Get time in the format h:mm A
    const timeString = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      .format(commenceTimeDate)
      .replace(/ (?=AM|PM)/, '');  // Removes the space before AM or PM

    // Extract day and month
    const monthDayString = new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'short' }).format(commenceTimeDate);

    const eventTime = `${monthDayString} ${timeString}`;

  return (
    <div className='bet-card'>
      <div className='bet-card-header flex'>
        <div className='bet-card-header-selection-container flex-column'>
            <p className='bet-card-header-selection'>{betDetails.outcome.name}</p>
            <p className='bet-card-header-market'>{betDetails.market}</p>
        </div>
        <div className='bet-card-header-odds-container'>
            <p className='bet-card-header-odds'>{betDetails.oddsPrice}</p>
        </div>
      </div>
      <div className='bet-card-event-details-container flex'>
        <p className='bet-card-event-details'>{`${eventDetails.away_team} @ ${eventDetails.home_team}`}</p>
        <p className='bet-card-event-time'>{eventTime}</p>
      </div>
      <div className='bet-card-wager-details flex'>
        <div className='bet-card-wager-container flex-column'>
          <p className='bet-card-wager-label'>Wager</p>
          <p className='bet-card-wager-amount'>{`$${betDetails.wager}`}</p>
        </div>
        <div className='bet-card-winnings-container flex-column'>
          <p className='bet-card-winnings-label'>Payout</p>
          <p className='bet-card-winnings-amount'>{`$${betDetails.payout}`}</p>
        </div>
      </div>
      <div className='bet-card-id-container'>
        {/* <p className='bet-card-share'>Share Button Here</p> */}
        <p className='bet-card-id'>{`bet ID: ${betDetails.betId}`}</p>
      </div>
    </div>
  )
}

export default SingleBetCard
