import React, { useState } from "react";

const VoteCountContext = React.createContext([{}, () => {}])

let initialState = []

const VoteCountProvider = props => {
    const [state, setState] = useState(initialState)

    return (
        <VoteCountContext.Provider value={[state, setState]}>
            {props.children}
        </VoteCountContext.Provider>
    )
}

export { VoteCountContext, VoteCountProvider }