import React from 'react'
import ParlayCardLeg from './ParlayCardLeg'
import { v4 as uuidv4 } from 'uuid';

const ParlayBetCard = ({ betDetails }) => {
    
  return (
    <div className='bet-card flex-column'>
      <div className='bet-card-header flex'>
        <div className='bet-card-header-selection-container'>
            <p className='bet-card-header-selection'>{betDetails.parlayLegs.length} Leg Parlay</p>
        </div>
        <div className='bet-card-header-odds-container'>
            <p className='bet-card-header-odds'>+{betDetails.parlayOdds}</p>
        </div>
      </div>
      {betDetails.parlayLegs.map((parlayLeg) => {
        
        return <ParlayCardLeg key={uuidv4()} legDetails={parlayLeg} />
      })}
      <div className='bet-card-wager-details flex'>
        <div className='bet-card-wager-container flex-column'>
          <p className='bet-card-wager-label'>Wager</p>
          <p className='bet-card-wager-amount'>{`$${betDetails.wager}`}</p>
        </div>
        <div className='bet-card-winnings-container flex-column'>
          <p className='bet-card-winnings-label'>Payout</p>
          <p className='bet-card-winnings-amount'>{`$${betDetails.payout}`}</p>
        </div>
      </div>
      <div className='bet-card-id-container'>
        {/* <p className='bet-card-share'>Share Button Here</p> */}
        <p className='bet-card-id'>{`bet ID: ${betDetails.betId}`}</p>
      </div>
    </div>
  )
}

export default ParlayBetCard
