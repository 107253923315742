import MIAsvg from '../assets/svgs/MIA.svg';
import BOSsvg from '../assets/svgs/BOS.svg';
import LALsvg from '../assets/svgs/LAL.svg';
import DENsvg from '../assets/svgs/DEN.svg';
import SEAsvg from '../assets/svgs/NHL/SEA.svg';
import DALsvg from '../assets/svgs/NHL/DAL.svg';
import VGKsvg from '../assets/svgs/NHL/VGK.svg';
import FLAsvg from '../assets/svgs/NHL/FLA.svg';
import CARsvg from '../assets/svgs/NHL/CAR.svg';
import ARIsvgNFL from '../assets/svgs/NFL/ARI.svg';
import ATLsvgNFL from '../assets/svgs/NFL/ATL.svg';
import BALsvgNFL from '../assets/svgs/NFL/BAL.svg';
import BUFsvgNFL from '../assets/svgs/NFL/BUF.svg';
import CARsvgNFL from '../assets/svgs/NFL/CAR.svg';
import CHIsvgNFL from '../assets/svgs/NFL/CHI.svg';
import CINsvgNFL from '../assets/svgs/NFL/CIN.svg';
import CLEsvgNFL from '../assets/svgs/NFL/CLE.svg';
import DALsvgNFL from '../assets/svgs/NFL/DAL.svg';
import DENsvgNFL from '../assets/svgs/NFL/DEN.svg';
import DETsvgNFL from '../assets/svgs/NFL/DET.svg';
import GBsvgNFL from '../assets/svgs/NFL/GB.svg';
import HOUsvgNFL from '../assets/svgs/NFL/HOU.svg';
import INDsvgNFL from '../assets/svgs/NFL/IND.svg';
import JACsvgNFL from '../assets/svgs/NFL/JAC.svg';
import KCsvgNFL from '../assets/svgs/NFL/KC.svg';
import LARsvgNFL from '../assets/svgs/NFL/LAR.svg';
import LACsvgNFL from '../assets/svgs/NFL/LAC.svg';
import LVsvgNFL from '../assets/svgs/NFL/LV.svg';
import MIAsvgNFL from '../assets/svgs/NFL/MIA.svg';
import MINsvgNFL from '../assets/svgs/NFL/MIN.svg';
import NEsvgNFL from '../assets/svgs/NFL/NE.svg';
import NOsvgNFL from '../assets/svgs/NFL/NO.svg';
import NYGsvgNFL from '../assets/svgs/NFL/NYG.svg';
import NYJsvgNFL from '../assets/svgs/NFL/NYJ.svg';
import PHIsvgNFL from '../assets/svgs/NFL/PHI.svg';
import PITsvgNFL from '../assets/svgs/NFL/PIT.svg';
import SEAsvgNFL from '../assets/svgs/NFL/SEA.svg';
import SFsvgNFL from '../assets/svgs/NFL/SF.svg';
import TBsvgNFL from '../assets/svgs/NFL/TB.svg';
import TENsvgNFL from '../assets/svgs/NFL/TEN.svg';
import WASsvgNFL from '../assets/svgs/NFL/WAS.svg';




const teams = 
    {
        'Miami Heat': {
            shorthand: 'MIA',
            fullName: 'Miami Heat',
            logo: MIAsvg,
        },
        'Boston Celtics': {
            shorthand: 'BOS',
            fullName: 'Boston Celtics',
            logo: BOSsvg,
        },
        'Los Angeles Lakers': {
            shorthand: 'LAL',
            fullName: 'Los Angeles Lakers',
            logo: LALsvg,
        },
        'Denver Nuggets': {
            shorthand: 'DEN',
            fullName: 'Denver Nuggets',
            logo: DENsvg,
        },
        'Seattle Kraken': {
            shorthand: 'SEA',
            fullName: 'Seattle Kraken',
            logo: SEAsvg,
        },
        'Dallas Stars': {
            shorthand: 'DAL',
            fullName: 'Dallas Stars',
            logo: DALsvg,
        },
        'Vegas Golden Knights': {
            shorthand: 'VGK',
            fullName: 'Vegas Golden Knights',
            logo: VGKsvg,
        },
        'Florida Panthers': {
            shorthand: 'FLA',
            fullName: 'Florida Panthers',
            logo: FLAsvg
        },
        'Carolina Hurricanes': {
            shorthand: 'CAR',
            fullName: 'Carolina Hurricanes',
            logo: CARsvg
        },
        // NFL
        'Arizona Cardinals': {
            shorthand: 'ARI',
            fullName: 'Arizona Cardinals',
            logo: ARIsvgNFL
        },
        'Atlanta Falcons': {
            shorthand: 'ATL',
            fullName: 'Atlanta Falcons',
            logo: ATLsvgNFL
        },
        'Baltimore Ravens': {
            shorthand: 'BAL',
            fullName: 'Baltimore Ravens',
            logo: BALsvgNFL
        },
        'Buffalo Bills': {
            shorthand: 'BUF',
            fullName: 'Buffalo Bills',
            logo: BUFsvgNFL
        },
        'Carolina Panthers': {
            shorthand: 'CAR',
            fullName: 'Carolina Panthers',
            logo: CARsvgNFL
        },
        'Chicago Bears': {
            shorthand: 'CHI',
            fullName: 'Chicago Bears',
            logo: CHIsvgNFL
        },
        'Cincinnati Bengals': {
            shorthand: 'CIN',
            fullName: 'Cincinnati Bengals',
            logo: CINsvgNFL
        },
        'Cleveland Browns': {
            shorthand: 'CLE',
            fullName: 'Cleveland Browns',
            logo: CLEsvgNFL
        },
        'Dallas Cowboys': {
            shorthand: 'DAL',
            fullName: 'Dallas Cowboys',
            logo: DALsvgNFL
        },
        'Denver Broncos': {
            shorthand: 'DEN',
            fullName: 'Denver Broncos',
            logo: DENsvgNFL
        },
        'Detroit Lions': {
            shorthand: 'DET',
            fullName: 'Detroit Lions',
            logo: DETsvgNFL
        },
        'Green Bay Packers': {
            shorthand: 'GB',
            fullName: 'Green Bay Packers',
            logo: GBsvgNFL
        },
        'Houston Texans': {
            shorthand: 'HOU',
            fullName: 'Houston Texans',
            logo: HOUsvgNFL
        },
        'Indianapolis Colts': {
            shorthand: 'IND',
            fullName: 'Indianapolis Colts',
            logo: INDsvgNFL
        },
        'Jacksonville Jaguars': {
            shorthand: 'JAC',
            fullName: 'Jacksonville Jaguars',
            logo: JACsvgNFL
        },
        'Kansas City Chiefs': {
            shorthand: 'KC',
            fullName: 'Kansas City Chiefs',
            logo: KCsvgNFL
        },
        'Los Angeles Rams': {
            shorthand: 'LAR',
            fullName: 'Los Angeles Rams',
            logo: LARsvgNFL
        },
        'Los Angeles Chargers': {
            shorthand: 'LAC',
            fullName: 'Los Angeles Chargers',
            logo: LACsvgNFL
        },
        'Las Vegas Raiders': {
            shorthand: 'LV',
            fullName: 'Las Vegas Raiders',
            logo: LVsvgNFL
        },
        'Miami Dolphins': {
            shorthand: 'MIA',
            fullName: 'Miami Dolphins',
            logo: MIAsvgNFL
        },
        'Minnesota Vikings': {
            shorthand: 'MIN',
            fullName: 'Minnesota Vikings',
            logo: MINsvgNFL
        },
        'New England Patriots': {
            shorthand: 'NE',
            fullName: 'New England Patriots',
            logo: NEsvgNFL
        },
        'New Orleans Saints': {
            shorthand: 'NO',
            fullName: 'New Orleans Saints',
            logo: NOsvgNFL
        },
        'New York Giants': {
            shorthand: 'NYG',
            fullName: 'New York Giants',
            logo: NYGsvgNFL
        },
        'New York Jets': {
            shorthand: 'NYJ',
            fullName: 'New York Jets',
            logo: NYJsvgNFL
        },
        'Philadelphia Eagles': {
            shorthand: 'PHI',
            fullName: 'Philadelphia Eagles',
            logo: PHIsvgNFL
        },
        'Pittsburgh Steelers': {
            shorthand: 'PIT',
            fullName: 'Pittsburgh Steelers',
            logo: PITsvgNFL
        },
        'Seattle Seahawks': {
            shorthand: 'SEA',
            fullName: 'Seattle Seahawks',
            logo: SEAsvgNFL
        },
        'San Francisco 49ers': {
            shorthand: 'SF',
            fullName: 'San Francisco 49ers',
            logo: SFsvgNFL
        },
        'Tampa Bay Buccaneers': {
            shorthand: 'TB',
            fullName: 'Tampa Bay Buccaneers',
            logo: TBsvgNFL
        },
        'Tennessee Titans': {
            shorthand: 'TEN',
            fullName: 'Tennessee Titans',
            logo: TENsvgNFL
        },
        'Washington Commanders': {
            shorthand: 'WAS',
            fullName: 'Washington Commanders',
            logo: WASsvgNFL
        },
    }



export default teams;