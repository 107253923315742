import React, { useCallback, useContext, useEffect, useState } from 'react';
import './App.css';
import { UserContext } from "./context/UserContext"
import Loader from "./components/Loader";
import { Card, Tab, Tabs } from "@blueprintjs/core"
import Login from './components/Login';
import Register from './components/Register';
import LoginContainer from './components/LoginContainer';
import MyBets from "./components/MyBets";
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate }
    from 'react-router-dom';
import About from './pages/about'
import OddsAggregator from './components/OddsAggregator';
import PickEm from './components/PickEm';

function App() {
  const [currentTab, setCurrentTab] = useState("login") // For login-container
  const [userContext, setUserContext] = useContext(UserContext) // Manage logged-in user


  // 'verifyUser' is called every 5 minutes to renew authentication token at 'users/refreshToken'
  const verifyUser = useCallback(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        setUserContext(oldValues => {
          return { ...oldValues, token: data.token }
        })
      } else {
        setUserContext(oldValues => {
          return { ...oldValues, token: null }
        })
      }

      // Call refreshToken every 5 minutes to renew the authentication token
      setTimeout(verifyUser, 5 * 60 * 1000)
    })
  }, [setUserContext]);

  useEffect(() => {
    if (userContext.token !== null) {
      verifyUser();
    }
  }, [verifyUser, userContext.token]);


  // Sync logout across tabs
  const syncLogout = useCallback(event => {
    if (event.key === "logout") {
      // If using react-router-dom, you may call history.push("/")
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("storage", syncLogout)
    return () => {
      window.removeEventListener("storage", syncLogout)
    }
  }, [syncLogout])


  return (
    <div className="App">
    <Router>
      <Navbar />
      <div className="content">
      <Routes>
        <Route path='/' element={<Navigate to='/odds-aggregator' />} />
        <Route path='/login' element={<LoginContainer />} />
        <Route path='/my-bets' element={<MyBets />} />
        <Route path='/odds-aggregator' element={<OddsAggregator />} />
        <Route path='/pick-em' element={<PickEm />} />
      </Routes>
      </div>
    </Router>
      
    </div>
  )
}


export default App;
