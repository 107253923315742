import React, { createContext, useState, useEffect, useContext } from 'react';

// Create Context Object
export const WindowWidthContext = createContext();

// Create a provider component
export const WindowWidthProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width to state on resize
      setWindowWidth(window.innerWidth);
    };
    
    // Subscribe to window resize event on mount
    window.addEventListener('resize', handleResize);
    
    // Unsubscribe from the event on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures effect is only run on mount and unmount

  return (
    <WindowWidthContext.Provider value={windowWidth}>
      {children}
    </WindowWidthContext.Provider>
  );
}
