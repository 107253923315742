import React, { useState } from 'react'
import Betslip from './Betslip';
import EventContainer from './EventContainer';
import SelectorBar from './SelectorBar';
import { SelectedPageContext } from '../context/SelectedPageContext';


function OddsAggregator() {
    // Define toggle state for Betslip
    const [ showBetslip, setShowBetslip ] = useState(false);
    const handleBetslipToggleClick = () => {
    setShowBetslip(prevBool => !prevBool);
    }

    // Define a state to hold selected bets. State will be updated when an 'OutcomeCard' component is clicked, to be passed to 'Betslip' component 
    const [ selectedBets, setSelectedBets ] = useState([]);

    // Definitions for 'sport' selector bar, for displaying that sports events in main UI.
    const [ selectedSport, setSelectedSport ] = useState('NFL'); // Default to NFL
    const handleSportChange = (sport) => { // Function definition for SelectorBar component.
        setSelectedSport(sport);
    };
    const sports = ['NFL', 'NBA', 'NHL'] // Options list for SelectorBar component.

    const [ selectedPage, setSelectedPage ] = useState('Pick'); // Default to Pick
 

    return (
        <SelectedPageContext.Provider value={[selectedPage, setSelectedPage]}>
        <div>
        {showBetslip && <Betslip selectedBets={selectedBets} setSelectedBets={setSelectedBets} betSlipTitle={"Betslip"}/>} 
        <button className="betslip-toggle" onClick={handleBetslipToggleClick}>Toggle Betslip</button>
        <SelectorBar
            options={sports}
            selectedOption={selectedSport}
            onOptionChange={handleSportChange}
        />
        {selectedSport === 'NFL' && <EventContainer selectedBets={selectedBets} setSelectedBets={setSelectedBets} sport={'nfl'}/>}
        {selectedSport === 'NBA' && <EventContainer selectedBets={selectedBets} setSelectedBets={setSelectedBets} sport={'nba'}/>}
        {selectedSport === 'NHL' && <EventContainer selectedBets={selectedBets} setSelectedBets={setSelectedBets} sport={'nhl'}/>}
        </div>
        </SelectedPageContext.Provider>
    )
}

export default OddsAggregator
