import React, { useState } from 'react'
import Loader from "./Loader";
import { Card, Tab, Tabs } from "@blueprintjs/core"
import Login from './Login';
import Register from './Register';


const LoginContainer = () => {
    const [currentTab, setCurrentTab] = useState("login") // For login-container
  
    return (
      <div className="App">
      
        <h1 className='app-title'>OddsBase</h1>
        
        <Card className="login-container" elevation="1">
            <Tabs id="Tabs" onChange={setCurrentTab} selectedTabId={currentTab}>
            <Tab id="login" title="Login" panel={<Login />} />
            <Tab id="register" title="Register" panel={<Register />} />
            <Tabs.Expander />
            </Tabs>
        </Card>
      
      
    </div>
  )
}

export default LoginContainer
