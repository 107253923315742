import React from 'react'
import ParlayCardLeg from './ParlayCardLeg'
import { v4 as uuidv4 } from 'uuid';

const ParlayVoteCard = ({ betDetails }) => {
  return (
    <div className='bet-card'>
      <div className='bet-card-header flex'>
        <div className='bet-card-header-selection-container'>
            <p className='bet-card-header-selection'>{`${betDetails.length} Leg Parlay`}</p>
        </div>
        {/* <div className='bet-card-header-odds-container'>
            <p className='bet-card-header-odds'>+</p>
        </div> */}
      </div>
      {betDetails.map((betDetail) => {
        
        return <ParlayCardLeg key={uuidv4()} legDetails={betDetail} />
      })}
      {/* <div className='bet-card-wager-details'>
        <p className='bet-card-wager-amount'>$8</p>
        <p className='bet-card-winnings-amount'>Payout Here</p>
      </div> */}
      <div className='bet-card-id-container'>
        <p className='bet-card-id'>{`bet ID: ${betDetails.betId}`}</p>
        {/* <p className='bet-card-share'>Share Button Here</p> */}
      </div>
    </div>
  )
}

export default ParlayVoteCard
