import React, { useContext, useState } from 'react';
import teams from '../data/teams';
import { WindowWidthContext } from '../context/WindowWidthContext'

const TeamInfo = ({ teamName }) => {
    const teamData = teams[teamName];

    const windowWidth = useContext(WindowWidthContext);

    // Determine the team name based on the window width
    const teamNameDisplayed = windowWidth <= 825 ? teamData.shorthand : teamData.fullName;

    return (
        <div className="event-team-info">
            <div className='team-logo-container'>
                <img className="team-logo" src={teamData.logo} alt={`${teamData.shorthand} svg`}></img>
            </div>
            <h3 className="event-team-name">{teamNameDisplayed}</h3>
        </div>
    )
}

export default TeamInfo
