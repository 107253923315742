import { Button, Card } from "@blueprintjs/core"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../context/UserContext"
import { EventsContext } from "../context/EventsContext";
import Loader from "./Loader"
import SingleBetCard from "./SingleBetCard";
import ParlayBetCard from "./ParlayBetCard";
import ParlayVoteCard from "./ParlayVoteCard";
import { v4 as uuidv4 } from 'uuid';

const MyBets = () => {
  const navigate = useNavigate();
  const [userContext, setUserContext] = useContext(UserContext)
  const [userBets, setUserBets] = useState({
    singleBets: [],
    parlayBets: [],
    pickEmVote: []
  })

  const [events, setEvents] = useState([]);

  // Retrieve events from API
  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "api/data")
        .then((response) => response.json())
        .then((data) => {
            setEvents(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}, []);

  const fetchUserDetails = useCallback(() => {
    if (!userContext.token) return; // ensure token is available

    fetch(process.env.REACT_APP_API_ENDPOINT + "users/me", {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        setUserContext(oldValues => {
          return { ...oldValues, details: data }
        })
      } else {
        if (response.status === 401) {
          // Edge case: when the token has expired.
          // This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          window.location.reload()
        } else {
          setUserContext(oldValues => {
            return { ...oldValues, details: null }
          })
        }
      }
    })
    
  }, [setUserContext, userContext.token])

  const fetchUserBets = useCallback(() => {
    if (!userContext.token) return; // ensure token is available

    fetch(process.env.REACT_APP_API_ENDPOINT + "users/bets", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async response => {
      const data = await response.json();
      setUserBets((prevBets) => {
        return { ...prevBets, singleBets: data.singleBets, parlayBets: data.parlayBets }
      });
    });
  }, [setUserContext, userContext.token])

  useEffect(() => {
    // fetch only when user details are not present
    if (!userContext.details) {
      fetchUserDetails()
    }
  }, [userContext.details, fetchUserDetails])

  useEffect(() => {
    fetchUserBets();
  }, [setUserContext, userContext.token])

  const logoutHandler = () => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "users/logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token}`,
      },
    }).then(async response => {
      setUserContext(oldValues => {
        return { ...oldValues, details: undefined, token: null }
      })
      window.localStorage.setItem("logout", Date.now())
      navigate('/login');
    })
  }

  const refetchHandler = () => {
    // set details to undefined so that spinner will be displayed and
    //  fetchUserDetails will be invoked from useEffect
    setUserContext(oldValues => {
      return { ...oldValues, details: undefined }
    })
  }

  

  return userContext.details === null ? (
    "Error Loading User details"
  ) : !userContext.details ? (
    <Loader />
  ) : (
    <EventsContext.Provider value={events}>
      <Card elevation="1">
        <div className="user-details">
          <div>
            <p>
              Welcome&nbsp;
              <strong>
                {userContext.details.firstName}
                {userContext.details.lastName &&
                  " " + userContext.details.lastName}
              </strong>!
            </p>
          </div>

          <div className="user-actions">
          <Button
              text="Logout"
              onClick={logoutHandler}
              minimal
              intent="primary"
            />
            {/* <Button text="Refetch" intent="primary" onClick={refetchHandler} /> */}
          </div>
        </div>
      </Card>
      <div className="user-bets-container">
        <div className="user-single-bets-section">
          <h2>Single Bets</h2>
          <div className="user-bet-cards-container">
            {userBets.singleBets.map((bet) => {
              const eventDetail = events.find(event => event.customEventId === bet.customEventId);
              if (!eventDetail) return null; // Don't render bet cards if events not yet fetched
              return <SingleBetCard key={uuidv4()} betDetails={bet} eventDetails={eventDetail}/>
            })}
          </div>
        </div>

        <div className="user-parlay-bets-section">
          <h2>Parlay Bets</h2>
          <div className="user-bet-cards-container">
            {userBets.parlayBets.map((bet) => {
              const eventsLength = events.length;
              if (eventsLength === 0) return null; // Don't render bet cards if events not yet fetched
              return <ParlayBetCard key={uuidv4()} betDetails={bet} />
            })}
          </div>
        </div>

        <div className="user-pickem-vote-section">
          <h2>Pick Em Vote</h2>
          <div className="user-bet-cards-container">
            {events.length > 0 ? userContext.details.parlayVote.length > 0 && <ParlayVoteCard key={uuidv4} betDetails={userContext.details.parlayVote} /> : null}
          </div>
        </div>
      </div>
    </EventsContext.Provider>
  )
}

export default MyBets