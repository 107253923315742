import React, { useContext } from 'react';
import teams from '../data/teams';
import OutcomeCard from './OutcomeCard';
import { v4 as uuidv4 } from 'uuid';
import TeamInfo from './TeamInfo';
import { VoteCountContext } from '../context/VoteCountContext';

const PickEmEventCard = ({ bookmaker, commenceTime, awayTeam, homeTeam, selectedBets, setSelectedBets, sport, customEventId }) => {
  const getMarketKey = (market) => { 
    switch (market) {
        case 'Moneyline':
            return 'h2h';
        case 'Spread':
            return 'spreads';
        case 'Total':
            return 'totals';
        default:
            return 'h2h';
    }
  }
  const generatecustomOutcomeId = (bookmaker, team, market, totalSide) => {
    const marketKey = getMarketKey(market);
    return bookmaker.markets?.find(o => o.key === marketKey)?.outcomes?.find(o => market === 'Total' ? o?.name === totalSide : o?.name === team)?.customOutcomeId;
  }

  const commenceTimeDate = new Date(commenceTime);

  // Get day of the week
  const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(commenceTimeDate);

  // Get time in the format h:mm A
  const timeString = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(commenceTimeDate);

  // Get the time zone abbreviation
  const timeZone = commenceTimeDate.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2];

  // Format the string
  const commenceTimeString = `${dayOfWeek} ${timeString} ${timeZone}`;

  const [ voteCountContext, setVoteCountContext ] = useContext(VoteCountContext);
  const voteCountEvent = voteCountContext?.find(e => e.customEventId === customEventId);
  
  const awayTeamData = teams[awayTeam];
  const homeTeamData = teams[homeTeam];

  const markets = ['Spread', 'Moneyline', 'Total']

  const fanduelBookmaker = bookmaker.find(bookmaker => bookmaker.key === 'fanduel');

  return (
    <div className="event-card">
      <div className="event-team-row">
        <TeamInfo teamName={awayTeamData.fullName} />
        
        <div className="event-team-odds-container">
          {markets.map((market) => {
            const customOutcomeId = generatecustomOutcomeId(fanduelBookmaker, awayTeamData.fullName, market, 'Over');
            let voteCount;
            if (voteCountEvent !== undefined) {
              voteCount = voteCountEvent.outcomes.find(e => e.customOutcomeId === customOutcomeId)?.voteCount || 0;
            } else {
              voteCount = 0;
            }
            return (
              <OutcomeCard key={uuidv4()} customOutcomeId={customOutcomeId} voteCount={voteCount} sport={sport} customEventId={customEventId} bookmaker={fanduelBookmaker} team={awayTeamData.fullName} totalSide={'Over'} selectedMarket={market} selectedBets={selectedBets} setSelectedBets={setSelectedBets} />
            );
          })}
        </div>
      </div>
      <div className={"event-card-middle-row"} >
        <div className={"event-at-symbol"} >
          <h3 className="event-middle-row-text">@</h3>
        </div>
        <div className={"event-date-container"} >
          <h3 className="event-middle-row-text">{commenceTimeString}</h3>
        </div>
      </div>
      <div className="event-team-row">
        <TeamInfo teamName={homeTeamData.fullName} />
        
        <div className="event-team-odds-container">
          {markets.map((market) => {
            const customOutcomeId = generatecustomOutcomeId(fanduelBookmaker, homeTeamData.fullName, market, 'Under');
            let voteCount
            if (voteCountEvent !== undefined) {
              voteCount = voteCountEvent.outcomes.find(e => e.customOutcomeId === customOutcomeId)?.voteCount || 0;
            } else {
              voteCount = 0
            }
            return (
              <OutcomeCard key={uuidv4()} customOutcomeId={customOutcomeId} voteCount={voteCount} sport={sport} customEventId={customEventId} bookmaker={fanduelBookmaker} team={homeTeamData.fullName} totalSide={'Under'} selectedMarket={market} selectedBets={selectedBets} setSelectedBets={setSelectedBets} />
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default PickEmEventCard
