import React, { useEffect, useState } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const BetslipParlayEntry = ({ bookmaker, bets, updateParlayWagersArray, parlayWagersArray }) => {

    // Define state variable for wager amount, will be changed whenever 'wager-amount-input' is changed
    const [ wager, setWager ] = useState(0);
    const handleWagerChange = (e) => {
        const wagerEntry = parseFloat(e.target.value)
        setWager(wagerEntry);
        
    }

    // Convert American odds to Decimal odds
    let convertAmericanToDecimal = (americanOdds) => {
        if (americanOdds > 0) {
            return (americanOdds / 100) + 1;
        } else {
            return (100 / Math.abs(americanOdds)) + 1;
        }
    };

    let decimalOdds = bets.map(bet => convertAmericanToDecimal(bet.oddsPrice));

    // Calculate parlay odds
    let parlayOdds = decimalOdds.reduce((accumulator, currentValue) => accumulator * currentValue, 1);
    let convertDecimalToAmerican = (decimalOdds) => {
        if (decimalOdds > 2) {
            return Math.round((decimalOdds - 1) * 100);
        } else {
            return Math.round(-100 / (decimalOdds - 1));
        }
    };
    
    let americanParlayOdds = convertDecimalToAmerican(parlayOdds);

    // Formula for payout depending on bet price (odds) and wager amount
    const getPayout = (price, wagerAmount) => {
        if (isNaN(wagerAmount) || wagerAmount === 0) {
            return '0.00';
        }
        if (price > 0) {
            return ((price/100) * wagerAmount).toFixed(2);
        } else {
            return ((100/Math.abs(price)) * wagerAmount).toFixed(2);
        }
    }

    const payout = `$${getPayout(americanParlayOdds, wager)}`;

    // On wager change, update parlayWagers array and payout info
    useEffect(() => {
        // Copy the parlay wagers array
        let updatedParlayWagers = [...parlayWagersArray.current];
        console.log(updatedParlayWagers);

        // Update wager in corresponding bookmaker's parlayWagersArray element
        const targetElementIndex = updatedParlayWagers.findIndex(element => element.bookmaker === bookmaker);

        updatedParlayWagers[targetElementIndex].wager = wager;
        updatedParlayWagers[targetElementIndex].odds = americanParlayOdds;
        updatedParlayWagers[targetElementIndex].payout = getPayout(americanParlayOdds, wager);
        console.log(updatedParlayWagers);
        // Set the new parlayWagers array
        updateParlayWagersArray(updatedParlayWagers);
    }, [ wager, americanParlayOdds])
    

    

    

  return (
    <div className="parlay-details-container">
        <div className='betslip-entry-bookmaker-container' >
                    
            <div className='betslip-wager-odds-container'>
                <div className='betslip-wager-odds-label'>Odds</div>
                <div className='betslip-wager-odds-text'>{((americanParlayOdds > 0) ? '+' : '') + americanParlayOdds}</div>
            </div>
            <div className='betslip-wager-amount-container'>
                <div className='betslip-wager-amount-label'>Wager</div>
                <input className='betslip-wager-amount-input' type='number' value={wager} onChange={handleWagerChange}></input>
            </div>
            <div className='betslip-wager-payout-container'>
                <div className='betslip-wager-payout-label'>Payout</div>
                <div className='betslip-wager-payout-text'>{payout}</div>
            </div>
            <div className='betslip-wager-link-container'>
                <a className='betslip-wager-link-anchor' href='#'>
                    <OpenInNewIcon />
                </a>
            </div>
        </div>
        <div>{bets.length} Leg Parlay</div>
    </div>
  )
}

export default BetslipParlayEntry
