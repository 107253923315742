import React, { useContext, useEffect, useRef, useState } from 'react'
import TeamInfo from './TeamInfo'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';


// Formula for payout depending on bet price (odds) and wager amount
const getPayout = (price, wagerAmount) => {
    if (isNaN(wagerAmount) || wagerAmount === 0) {
        return '0.00';
    }
    if (price > 0) {
        return ((price/100) * wagerAmount).toFixed(2);
    } else {
        return ((100/Math.abs(price)) * wagerAmount).toFixed(2);
    }
}

const BetslipEntry = ({ events, selectedBet, selectedBets, setSelectedBets, updateWagersArray, wagersArray }) => {

    // Check if wager exists for bet
    const currentWager = wagersArray?.current.find(wager => wager.customOutcomeId === selectedBet.customOutcomeId)?.wager || 0;

    // Define state variable for wager amount, will be changed whenever 'wager-amount-input' is changed
    const [ wager, setWager ] = useState(currentWager);
    const handleWagerChange = async (e) => {
        const wagerEntry = parseFloat(e.target.value)
        setWager(wagerEntry);
        
    }

    // Access bet outcome price to calculate payout
    const betPrice = selectedBet.outcome.price;
    const payout = `$${getPayout(betPrice, wager)}`;

    // On wager change, update wagersArray and payout info
    useEffect(() => {
        // Copy the current wagers array
        let updatedWagers = [...wagersArray.current];

        // Check if the current bet's customOutcomeId already exists in the wagers array
        const existingBetIndex = updatedWagers.findIndex(bet => bet.customOutcomeId === selectedBet.customOutcomeId);

        // Calculate updated payout
        const updatedPayout = getPayout(betPrice, wager);

        if (existingBetIndex !== -1) {
            // If it does, update it's wager and payout
            updatedWagers[existingBetIndex].wager = wager;
            updatedWagers[existingBetIndex].payout = updatedPayout
        } else {
            // If it doesn't, add the new wager
            updatedWagers.push({
                customOutcomeId: selectedBet.customOutcomeId,
                wager: wager,
                payout: updatedPayout,
            });
        }

        // Set the new wagers array
        console.log(updatedWagers);
        updateWagersArray(updatedWagers);
    }, [ wager, betPrice ]);

    // Access event based on customEventId
    const event = events.find(o => o.customEventId === selectedBet.customEventId);
   
    
    

    const handleEntryDeleteClick = () => {
        setSelectedBets(prevSelectedBets => {
            return prevSelectedBets.filter(bet => bet.customOutcomeId !== selectedBet.customOutcomeId);
        })
    }


    return (
        <div>
            <div className='betslip-entry'>
                
                <div className='betslip-entry-bookmaker-container' >
                    
                    <div className='betslip-wager-odds-container'>
                        <div className='betslip-wager-odds-label'>Odds</div>
                        <div className='betslip-wager-odds-text'>{selectedBet.oddsPrice}</div>
                    </div>
                    <div className='betslip-wager-amount-container'>
                        <div className='betslip-wager-amount-label'>Wager</div>
                        <input className='betslip-wager-amount-input' type='number' value={wager} onChange={handleWagerChange}></input>
                    </div>
                    <div className='betslip-wager-payout-container'>
                        <div className='betslip-wager-payout-label'>Payout</div>
                        <div className='betslip-wager-payout-text'>{payout}</div>
                    </div>
                    <div className='betslip-wager-link-container'>
                        <a className='betslip-wager-link-anchor' href='#'>
                            <OpenInNewIcon />
                        </a>
                    </div>
                    
                </div>
                <div className='betslip-entry-event-container' >
                    <TeamInfo teamName={event.home_team} />
                    <h3>vs</h3>
                    <TeamInfo teamName={event.away_team} />
                </div>
                <div className='betslip-entry-outcome-container' >
                    <div className='betslip-entry-outcome-text'>
                        <div className='betslip-entry-outcome-side'>{selectedBet.outcome.name}</div>
                        <div className='betslip-entry-outcome-point'>{selectedBet.oddsPoint}</div>
                    </div>
                    <div className='betslip-entry-market-text'>{selectedBet.market}</div>
                </div>
                <div className='betslip-entry-delete-container'>
                    <div className='betslip-entry-delete-button' onClick={handleEntryDeleteClick}>
                        <DeleteIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BetslipEntry
