import { React, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../context/UserContext"

import BetslipEntry from './BetslipEntry';
import BookmakerLogo from './BookmakerLogo';

import { v4 as uuidv4 } from 'uuid';
import BetslipParlayEntry from './BetslipParlayEntry';

const Betslip = ({ selectedBets, setSelectedBets, betSlipTitle, onBetSubmitted }) => {
    const navigate = useNavigate();

    const fanduelBets = selectedBets.filter(bet => bet.bookmaker === 'fanduel');
    const draftkingsBets = selectedBets.filter(bet => bet.bookmaker === 'draftkings');
    const betmgmBets = selectedBets.filter(bet => bet.bookmaker === 'betmgm');

    const [userContext, setUserContext] = useContext(UserContext)
    // Define state for events fetched from API
    const [ events, SetEvents ] = useState([]);

    // Define state for parlay toggle switch states
    const [ parlayStates, setParlayStates ] = useState({
        fanduelParlaySwitch: false,
        draftkingsParlaySwitch: false,
        betmgmParlaySwitch: false,
    })

    // Define reference for array of wager amounts
    const wagersArray = useRef([]);
    const changeWagersArray = (value) => { wagersArray.current = value}; 
    // Define one for array of parlay wagers
    const parlayWagersArray = useRef([
        {
            bookmaker: 'fanduel',
            wager: 0,
            odds: 0,
            payout: 0,
        },
        {
            bookmaker: 'draftkings',
            wager: 0,
            odds: 0,
            payout: 0,
        },
        {
            bookmaker: 'betmgm',
            wager: 0,
            odds: 0,
            payout: 0,
        }
    ]);
    const changeParlayWagersArray = (value) => { parlayWagersArray.current = value};

    // Define state for parlay odds 

    // Fetch event data to be matched w/ customEventId of bets in selectedBets
    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "api/data")
            .then((response) => response.json())
            .then((data) => {
                SetEvents(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    // Handle Betslip Submission
    const handleBetslipSubmitClick = (() => {
        const userToken = userContext.token;
        if (!userToken) {
            navigate('/login');
        } else {

            //  Handle if submitting a pick 'em vote
            if (betSlipTitle === "Pick 'em Pool") {
                fetch(process.env.REACT_APP_API_ENDPOINT + "users/submitbetslip", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${userToken}`, // Include the token in the request headers
                    },
                    body: JSON.stringify({ selectedBets }), // Send the selectedBets as the request body
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    window.location.reload();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
                onBetSubmitted(prevState => !prevState);
                return
            } else {
                submitBets();
                // window.location.reload();
            }
        }
    })

    const submitBets = (async () => {
        const userToken = userContext.token;
        // Make an array of bets to be submitted using all selected bets
        const betsArray = selectedBets.map(bet => ({
            ...bet,
            wager: wagersArray.current.find(wager => wager.customOutcomeId === bet.customOutcomeId)?.wager || 0,
            payout: wagersArray.current.find(wager => wager.customOutcomeId === bet.customOutcomeId)?.payout || 0,
        }))
        console.log(betsArray);

        // Filter for bets w/ a wager of <=0 
        const filteredBetsArray = betsArray.filter(bet => bet.wager > 0);
        console.log(filteredBetsArray);

        if (filteredBetsArray.length > 0) {

            // Add time stamp and unique betID
            const timeStampedBetsArray = filteredBetsArray.map(bet => ({
                betId: uuidv4(),
                timestamp: new Date().toISOString(),
                ...bet // Include other properties of bet element
            }));
            console.log(timeStampedBetsArray)

            // Submit w/ fetch API
            await fetch(process.env.REACT_APP_API_ENDPOINT + "users/bets/singleBets", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`, // Include the token in the request headers
                },
                body: JSON.stringify({ timeStampedBetsArray }), // Send the selectedBets as the request body
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                // window.location.reload();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        }
        
        // Check if any parlays
        let parlays = [];

        if (parlayStates.fanduelParlaySwitch) {
            const newParlay = {
                betId: uuidv4(),
                timestamp: new Date().toISOString(),
                parlayLegs: [...fanduelBets],
                parlayOdds: parlayWagersArray.current.find(element => element.bookmaker === 'fanduel').odds,
                wager: parlayWagersArray.current.find(element => element.bookmaker === 'fanduel').wager,
                payout: parlayWagersArray.current.find(element => element.bookmaker === 'fanduel').payout,
            }
            parlays.push(newParlay);
            console.log(newParlay);
        }
        
        // Repeat for other bookmakers
        if (parlayStates.draftkingsParlaySwitch) {
            const newParlay = {
                betId: uuidv4(),
                timestamp: new Date().toISOString(),
                parlayLegs: [...draftkingsBets],
                parlayOdds: parlayWagersArray.current.find(element => element.bookmaker === 'draftkings').odds,
                wager: parlayWagersArray.current.find(element => element.bookmaker === 'draftkings').wager,
                payout: parlayWagersArray.current.find(element => element.bookmaker === 'draftkings').payout,
            }
            console.log(newParlay);
            parlays.push(newParlay);
        }
        if (parlayStates.betmgmParlaySwitch) {
            const newParlay = {
                betId: uuidv4(),
                timestamp: new Date().toISOString(),
                parlayLegs: [...betmgmBets],
                parlayOdds: parlayWagersArray.current.find(element => element.bookmaker === 'betmgm').odds,
                wager: parlayWagersArray.current.find(element => element.bookmaker === 'betmgm').wager,
                payout: parlayWagersArray.current.find(element => element.bookmaker === 'betmgm').payout,
            }
            console.log(newParlay);
            parlays.push(newParlay);

        }
        // Submit parlay information
        await fetch(process.env.REACT_APP_API_ENDPOINT + "users/bets/parlayBets", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`, // Include the token in the request headers
            },
            body: JSON.stringify({ parlays }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // window.location.reload();
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    })

    // Handle Parlay Toggle Switch Click
    const handleParlaySwitchClick = ((e) => {
        const targetSwitch = e.target.id;
        setParlayStates((prevState) => {
            switch (targetSwitch) {
                case 'fanduelparlaySwitch':
                    return { ...prevState, fanduelParlaySwitch: !prevState.fanduelParlaySwitch };
                case 'draftkingsParlaySwitch':
                    return { ...prevState, draftkingsParlaySwitch: !prevState.draftkingsParlaySwitch };
                case 'betmgmParlaySwitch':
                    return { ...prevState, betmgmParlaySwitch: !prevState.betmgmParlaySwitch };
                default:
                    return prevState; // If none of the known switches was clicked, just return the previous state
            }
        })
    })

    // Reset parlayStates to 'false' when all bets for the respective bookmaker are removed. This prevents parlay toggle and actual state from becoming out of sync.
    useEffect(() => {
        const updateParlayStates = () => {
            let newParlayStates = { ...parlayStates };
            if (selectedBets.filter(bet => bet.bookmaker === 'fanduel').length === 0) {
                newParlayStates.fanduelParlaySwitch = false;
            }
            if (selectedBets.filter(bet => bet.bookmaker === 'draftkings').length === 0) {
                newParlayStates.draftkingsParlaySwitch = false;
            }
            if (selectedBets.filter(bet => bet.bookmaker === 'betmgm').length === 0) {
                newParlayStates.betmgmParlaySwitch = false;
            }
            setParlayStates(newParlayStates);
        }
        
        updateParlayStates();
    }, [selectedBets]);

    if (selectedBets[0] && events.length > 0) {       
        
        return (
            <div className="betslip-container">
                <h1 className='betslip-header'>{betSlipTitle}</h1>
                <button className="betslip-submit submit" onClick={handleBetslipSubmitClick}>Submit</button>
                {/* Make a section in the betslip for each bookmaker where at least one bet is selected.  */}
                {fanduelBets.length > 0 && 
                <div className="betslip-bookmaker-header fanduel" >
                    <BookmakerLogo bookmakerName={'fanduel'} />
                    <div className='parlay-switch-container'>
                        <p>Parlay?</p>
                        <label className="parlay-switch fanduel">
                            <input type="checkbox" id="fanduelparlaySwitch" onClick={handleParlaySwitchClick}></input>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                }
                {parlayStates.fanduelParlaySwitch && fanduelBets.length > 0 && <BetslipParlayEntry bookmaker={'fanduel'} bets={fanduelBets} updateParlayWagersArray={changeParlayWagersArray} parlayWagersArray={parlayWagersArray} />}
                {fanduelBets.map((selectedBet) => (
                    <BetslipEntry key={uuidv4()} events={events} selectedBet={selectedBet} selectedBets={selectedBets} setSelectedBets={setSelectedBets} updateWagersArray={changeWagersArray} wagersArray={wagersArray} />
                ))}
                {draftkingsBets.length > 0 && 
                <div className="betslip-bookmaker-header draftkings" >
                    <BookmakerLogo bookmakerName={'draftkings'} />
                    <div className='parlay-switch-container'>
                        <p>Parlay?</p>
                        <label className="parlay-switch draftkings">
                            <input type="checkbox" id="draftkingsParlaySwitch" onClick={handleParlaySwitchClick}></input>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                }
                {parlayStates.draftkingsParlaySwitch && draftkingsBets.length > 0 && <BetslipParlayEntry bookmaker={'draftkings'} bets={draftkingsBets} updateParlayWagersArray={changeParlayWagersArray} parlayWagersArray={parlayWagersArray} />}
                {draftkingsBets.map((selectedBet) => (
                    <BetslipEntry key={uuidv4()} events={events} selectedBet={selectedBet} selectedBets={selectedBets} setSelectedBets={setSelectedBets} updateWagersArray={changeWagersArray} wagersArray={wagersArray}/>
                ))}
                {betmgmBets.length > 0 && 
                <div className="betslip-bookmaker-header betmgm" >
                    <BookmakerLogo bookmakerName={'betmgm'} />
                    <div className='parlay-switch-container'>
                        <p>Parlay?</p>
                        <label className="parlay-switch betmgm">
                            <input type="checkbox" id="betmgmParlaySwitch" onClick={handleParlaySwitchClick}></input>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                }
                {parlayStates.betmgmParlaySwitch && betmgmBets.length > 0 && <BetslipParlayEntry bookmaker={'betmgm'} bets={betmgmBets} updateParlayWagersArray={changeParlayWagersArray} parlayWagersArray={parlayWagersArray} />}
                {betmgmBets.map((selectedBet) => (
                    <BetslipEntry key={uuidv4()} events={events} selectedBet={selectedBet} selectedBets={selectedBets} setSelectedBets={setSelectedBets} updateWagersArray={changeWagersArray} wagersArray={wagersArray}/>
                ))}
            </div>
        )
    } else return (
        <div className="betslip-container">
                <h1 className='betslip-header'>{betSlipTitle}</h1>
        </div>
    )}

export default Betslip
