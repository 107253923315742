import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../context/UserContext"
import Betslip from './Betslip';
import PickEmEventContainer from './PickEmEventContainer'
import SelectorBar from './SelectorBar';
import { VoteCountContext } from '../context/VoteCountContext';
import { EventsContext } from '../context/EventsContext';
import { SelectedPageContext } from '../context/SelectedPageContext';

function PickEm() {
  const navigate = useNavigate();

  const [ userContext ] = useContext(UserContext);
  
  const [ , setVoteCountContext ] = useContext(VoteCountContext);

  const [games, setGames] = useState([]);
  const sport = 'nfl';

  const [ winningBets, setWinningBets ] = useState([]);

  // Retrieve events of corresponding sport from API
  useEffect(() => {
      fetch(process.env.REACT_APP_API_ENDPOINT + "api/" + sport)
          .then((response) => response.json())
          .then((data) => {
              setGames(data);
          })
          .catch((error) => {
              console.error('Error:', error);
          });
  }, [sport]);

  // Fetch vote counts and save context
  const fetchVoteCount = useCallback(async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'api/results', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
      });
      const result = await response.json();

      await setVoteCountContext(result?.data || []);
      await setWinningBets(result.winners);
    } catch (error) {
      console.error('Failed to fetch vote count:', error);
      setVoteCountContext(null);
    }
  }, [setVoteCountContext]);

  useEffect(() => {
    if (games.length) {
      fetchVoteCount();
    }
  }, [games, fetchVoteCount]);

  // Define toggle state for Betslip
  const [ showBetslip, setShowBetslip ] = useState(false);
  const handleBetslipToggleClick = () => {
  setShowBetslip(prevBool => !prevBool);
  }

  // Define a state to hold selected bets. State will be updated when an 'OutcomeCard' component is clicked, to be passed to 'Betslip' component to be shown on Betslip
  const [ selectedBets, setSelectedBets ] = useState([]);
  const [ betSubmitted, setBetSubmitted ] = useState(false);

  // Definitions for 'Page' selector bar, for displaying the results for that page in main UI.
  const [ selectedPage, setSelectedPage ] = useState('Pick'); // Default to Pick
  const handlePageChange = (page) => { // Function definition for SelectorBar component.
      setSelectedPage(page);
  };
  const pages = ['Pick', 'My Selection', 'Current Results'] // Options list for SelectorBar component.
  
  // Retrieve user's saved submission
  const [userBets, setUserBets] = useState([]);

  const fetchUserBets = useCallback(async () => {
    const userToken = userContext.token;
    if (!userToken) {
      return; // return early if no user logged in
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/mypickem', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`, // Include the token in the request headers
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();
      setUserBets(data.bets);  // assuming the response has a 'bets' property
      } catch (error) {
      console.error('Failed to fetch user bets:', error);
      }
  }, [userContext.token, setUserBets]);
  useEffect(() => {
    if (userContext.token) {
      fetchUserBets();
    }
  }, [userContext.token, fetchUserBets]);

  const handlePickEmSubmitClick = (() => {
    const userToken = userContext.token;
    if (!userToken) {
      navigate('/login');
    } else {
      fetch(process.env.REACT_APP_API_ENDPOINT + "users/submitbetslip", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`, // Include the token in the request headers
        },
        body: JSON.stringify({ selectedBets }), // Send the selectedBets as the request body
      })
      .then(response => response.json())
      .then(data => {
          console.log('Success:', data);
          window.location.reload();
      })
      .catch((error) => {
          console.error('Error:', error);
      });
      setBetSubmitted(prevState => !prevState);
    }
    
})

  return (
    <SelectedPageContext.Provider value={[selectedPage, setSelectedPage]}>
    <EventsContext.Provider value={games}>
    <div>
      {showBetslip && <Betslip selectedBets={selectedBets} setSelectedBets={setSelectedBets} betSlipTitle={"Pick 'em Pool"} onBetSubmitted={setBetSubmitted} />} 
      <button className="betslip-toggle" onClick={handleBetslipToggleClick}>Toggle Betslip</button>
      <SelectorBar
        options={pages}
        selectedOption={selectedPage}
        onOptionChange={handlePageChange}
      />      
      {selectedPage === 'Pick' && <PickEmEventContainer selectedBets={selectedBets} setSelectedBets={setSelectedBets} sport={'nfl'} />}
      {selectedPage === 'My Selection' && <PickEmEventContainer selectedBets={userBets} setSelectedBets={setSelectedBets} sport={'nfl'} />}
      {selectedPage === 'Current Results' && <PickEmEventContainer selectedBets={winningBets} setSelectedBets={setSelectedBets} sport={'nfl'} />}
      
    </div>
    <button className="pickem-submit submit" onClick={handlePickEmSubmitClick}>Submit</button>
    </EventsContext.Provider>
    </SelectedPageContext.Provider>
  )
}

export default PickEm
