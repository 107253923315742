import { React, useState, useEffect } from 'react'
import EventCard from './EventCard'
import SelectorBar from './SelectorBar';
import { v4 as uuidv4 } from 'uuid';

const EventContainer = ({ sport, selectedBets, setSelectedBets }) => {

    // Definitions for SelectorBar component
    const [selectedMarket, setSelectedMarket] = useState('Moneyline');
    const [games, setGames] = useState([]);
    const markets = ['Spread', 'Moneyline', 'Total'];

    const handleMarketChange = (market) => {
        setSelectedMarket(market);
    };

    // Retrieve events of corresponding sport from API
    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "api/" + sport)
            .then((response) => response.json())
            .then((data) => {
                setGames(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [sport]);

    return (
        <div className="events-container">
            <div className="events-header">
            
            <SelectorBar 
                options={markets}
                selectedOption={selectedMarket} 
                onOptionChange={handleMarketChange} />
            </div>
            {games.map((game) => (
                <EventCard key={uuidv4()} sport={sport} customEventId={game.customEventId} commenceTime={game.commence_time} awayTeam={game.away_team} homeTeam={game.home_team} bookmakers={game.bookmakers} selectedMarket={selectedMarket} selectedBets={selectedBets} setSelectedBets={setSelectedBets}></EventCard>
            ))}
        </div>
    )
}

export default EventContainer
