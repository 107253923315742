import React, { useContext, useState } from "react";
import { Nav, NavLink, NavMenu }
    from "./NavbarElements";
import { UserContext } from "../../context/UserContext";
 
const Navbar = () => {
    const [userContext, setUserContext] = useContext(UserContext) // Manage logged-in user

    return !userContext.token ? (
        <>
            <Nav>
                <NavMenu>
                    <NavLink to="/login" >
                        Login
                    </NavLink>
                    <NavLink to="/odds-aggregator" >
                        Odds Aggregator
                    </NavLink>
                    <NavLink to="/pick-em" >
                        Pick Em
                    </NavLink>
                </NavMenu>
            </Nav>
        </>
    ) : (
        <>
            <Nav>
                <NavMenu>
                    <NavLink to="/my-bets" >
                        My Bets
                    </NavLink>
                    <NavLink to="/odds-aggregator" >
                        Odds Aggregator
                    </NavLink>
                    <NavLink to="/pick-em" >
                        Pick Em
                    </NavLink>
                </NavMenu>
            </Nav>
        </>
    ) 
};
 
export default Navbar;