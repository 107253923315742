import { React, useContext, useEffect, useMemo } from 'react'
import { SelectedPageContext } from '../context/SelectedPageContext';

import BookmakerLogo from './BookmakerLogo';

const OutcomeCard = ({ bookmaker, customEventId, selectedMarket, selectedBets, setSelectedBets, sport, totalSide, team, customOutcomeId, voteCount }) => {
    const [ selectedPage, setSelectedPage ] = useContext(SelectedPageContext);


    // Find the outcome object from the market that is selected
    const findOutcome = useMemo(() => {
        // Convert selectedMarket to match the string return in API.
        const getMarketKey = () => { 
            switch (selectedMarket) {
                case 'Moneyline':
                    return 'h2h';
                case 'Spread':
                    return 'spreads';
                case 'Total':
                    return 'totals';
                default:
                    return 'h2h';
            }
        }
        const marketKey = getMarketKey();
        return bookmaker.markets.find(o => o.key === marketKey)?.outcomes.find(o => selectedMarket === 'Total' ? o.name === totalSide : o.name === team);
    }, [bookmaker, selectedMarket, totalSide, team]);

    // Return odds Point from the outcome object to be displayed
    const oddsPointSrc = useMemo(() => {
        const point = findOutcome?.point;
        if (selectedMarket === 'Total') {
            return totalSide === 'Over' ? `O ${point}` : `U ${point}`;
        }
        return point > 0 ? `+${point}` : point;
    }, [selectedMarket, totalSide, findOutcome]);

    // Return odds Price from the outcome object to be displayed
    const oddsPriceSrc = useMemo(() => {
        const price = findOutcome?.price;
        return price > 0 ? `+${price}` : price;
    }, [findOutcome]);
    
    // Store outcome ID from outcome object
    const outcomeIDSrc = useMemo(() => findOutcome?.customOutcomeId, [findOutcome]);

    // Construct object to be added to selected bets w/ click handler.
    const outcome = {
        sport: sport,
        customOutcomeId: outcomeIDSrc,
        customEventId: customEventId,
        market: selectedMarket,
        outcome: findOutcome,
        oddsPoint: oddsPointSrc,
        oddsPrice: oddsPriceSrc,
        bookmaker: bookmaker.key
    };

    // Click handler for Outcome Card that toggles inclusion in selectedBets in App. To be added to betslip. 
    const handleOutcomeClick = () => {
        setSelectedPage('Pick');
        setSelectedBets(prevSelectedBets => {
            // Check if conflicting bet (same event, same bookmaker) is already selected. 
            const conflictingSelected = prevSelectedBets.some(bet => bet.customEventId === outcome.customEventId && bet.bookmaker === outcome.bookmaker && bet.market === outcome.market);
            // Check if bet is already in the array
            const alreadySelected = prevSelectedBets.some(bet => bet.customOutcomeId === outcome.customOutcomeId);

            if (alreadySelected) { // If it is, remove it
                return prevSelectedBets.filter(bet => bet.customOutcomeId !== outcome.customOutcomeId);
            } else if (conflictingSelected) { // If it is, remove it and add the newly selected one
                const newBets = prevSelectedBets.filter(bet => bet.customEventId !== outcome.customEventId || bet.bookmaker !== outcome.bookmaker || bet.market !== outcome.market);
                return [...newBets, outcome];
            } else { // If not, simply add it
                return [...prevSelectedBets, outcome]
            }
        })
    }



  
    return (
        // <div className={selectedBets.some(bet => bet?.customOutcomeId === outcome.customOutcomeId) ? "event-team-maker selected" : "event-team-maker"} onClick={handleOutcomeClick}>
        //     <BookmakerLogo bookmakerName={bookmaker.key} />
        //     <div className="odds-text-container">
        //         <p className="odds-text point">{oddsPointSrc}</p>
        //         <p className="odds-text price">{oddsPriceSrc}</p>
        //     </div>
        //     {selectedPage === 'Current Results' && <div className="vote-count-container">
        //     <span className="vote-count">{voteCount !== null ? voteCount : 'Loading...'}</span>
        //     </div>}
        // </div>
        <div className={"outcome-container"}>
            <div className={selectedBets.some(bet => bet?.customOutcomeId === outcome.customOutcomeId) ? "event-team-maker selected" : "event-team-maker"} onClick={handleOutcomeClick}>
                <BookmakerLogo bookmakerName={bookmaker.key} />
                <div className="odds-text-container">
                    <p className="odds-text point">{oddsPointSrc}</p>
                    <p className="odds-text price">{oddsPriceSrc}</p>
                </div>
                
            </div>
            {selectedPage === 'Current Results' && <div className="vote-count-container">
                <span className="vote-count">{`Votes: ${voteCount !== null ? voteCount : 'Loading...'}`}</span>
            </div>}
        </div>
        
    )
}

export default OutcomeCard
