import React from 'react'
import fanDuelSVG from '../assets/svgs/fanduel_short.svg';
import betMgmSVG from '../assets/svgs/betmgm_short.svg';
import draftKingsSVG from '../assets/svgs/caesar_short.svg';

const BookmakerLogo = ({ bookmakerName }) => {
    const getLogoSrc = () => {
        switch (bookmakerName) {
            case 'fanduel':
                return fanDuelSVG;
            case 'betmgm':
                return betMgmSVG;
            case 'draftkings':
                return draftKingsSVG;
            default:
                return null;
        }
    }

    const logoSrc = getLogoSrc();

    return (
        <div className={`bookmaker-logo-container logo-${bookmakerName}`}>
            <img className="bookmaker-logo" src={logoSrc} alt={`${bookmakerName} svg`}></img>
        </div>
    )
}

export default BookmakerLogo
